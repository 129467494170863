<template>
  <div class="recommend-formula">
    <div class="header">
      <div class="title-box">
        <p class="title">公式推介</p>
        <intro-default cont=""></intro-default>
      </div>
    </div>
    <div class="lz_cont">
      <div class="table">
        <el-table :data="data" border style="width: 100%" header-cell-class-name="table-header-cell"
          cell-class-name="table-cell" row-class-name="table-row" size="small" row-key="id">
          <el-table-column label="号码" align="center" prop="number" width="150"></el-table-column>
          <el-table-column label="公式" align="center" prop="formula"></el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
import { Table, TableColumn } from 'element-ui'
export default {
  name: 'RecommendFormula',
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn
  },
  props: {
    code: String,
    id: String
  },
  filters: {
  },
  data() {
    return {
      data: []
    }
  },
  computed: {
  },
  watch: {
    code() {
      this.requestGetFormula()
    }
  },
  mounted() {
    this.requestGetFormula()
  },
  methods: {
    refresh() {
      this.requestGetFormula()
    },
    requestGetFormula() {
      this.$loading.show()
      this.$api.formula(this.code).then(res => {
        this.rankIndex = 0
        this.data = res.data
        this.$loading.hide()
      }).catch(err => {
        this.$loading.hide()
        this.$message.error(err)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@scss/pc/variables';
@import "~@scss/pc/mixin";

.recommend-formula {
  >.header {
    padding: 0 20px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    border-bottom: thin $color-border solid;

    >.title-box {
      display: flex;
      align-items: center;
      height: 49px;

      >.title {
        font-size: 20px;
        font-weight: 400;
        color: #222;
      }
    }

    >.search-box {
      display: flex;
      align-items: center;

      >.title {
        margin-right: 15px;
        font-size: 14px;
        color: #222;
      }

      >.icon-search {
        margin-left: 10px;
        width: 48px;
        height: 24px;
        display: block;
      }
    }
  }

  .lz_cont {
    padding: 20px;
    padding-top: 30px;
    padding-bottom: 0;
    box-sizing: border-box;
  }

  .types {
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0;
    margin: 0;
    margin-bottom: 30px;

    >li {
      cursor: pointer;
      list-style: none;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      font-weight: 400;
      color: #858586;
      background-color: #E3E3E5;
      width: 70px;
      height: 24px;
      border-radius: 4px;

      &.active {
        color: #FFFFFF;
        background-color: #FA3E3E;
      }

      &+li {
        margin-left: 10px;
      }
    }
  }
}
</style>

<style>
.recommend-formula>.table {
  margin-top: 20px;
}

.recommend-formula>.table .table-header-cell {
  background-color: #f9f9f9;
  font-size: 14px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.5);
  padding: 0;
  height: 40px;
}

.recommend-formula>.table .table-header-cell>.cell {
  padding: 0;
}

.recommend-formula>.table .table-cell {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.8);
  padding: 0;
  height: 33px;
}

.recommend-formula>.table .table-cell>.cell {
  padding: 0;
}

.recommend-formula>.table .table-row:hover>td,
.recommend-formula>.table .table-row .current-row>td {
  background-color: rgba(255, 204, 170, 0.4);
}
</style>
